import { Component, OnInit } from '@angular/core';
import { AlarmService } from "../../services/alarm.service";
import { AnalyticalDashboardService } from "src/app/services/analytical-dashboard.service";
import { AuthenticationService } from '../../services/authentication.service';
import { Utility } from "src/app/utils/app-utils";
import { MapService } from '../../services/map.service';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})

export class ZoneComponent implements OnInit {
  selectedAlarm: any;
  utility = Utility;
  session_token: any;
  iframeUrl: any;
  zoneId: any;
  sensorId: any;

  constructor(private alarmService: AlarmService, private authenticationService: AuthenticationService, public analyticalDashboardService: AnalyticalDashboardService, public mapService: MapService) { }

  ngOnInit() {
    this.session_token = this.authenticationService.getAuthToken();
    this.selectedAlarmListener();
  }

  async selectedAlarmListener() {
    this.selectedAlarm = this.alarmService.selectedAlarm;
    if (this.selectedAlarm.sensor) {
      this.zoneId = this.selectedAlarm.sensor.zone._id || this.selectedAlarm.sensor.zone.id;
      this.sensorId = this.selectedAlarm.sensor._id || this.selectedAlarm.sensor.id;
      let response = await this.alarmService.drawZone(this.zoneId, this.sensorId, this.session_token);
      if (response.success === true) {
        this.iframeUrl = response.url;
      }
    }
  }

  ngOnDestroy(): void {
  }

}