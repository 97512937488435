<div class="container-fluid">
  <div class="header-wrapper d-flex justify-content-between">
    <!-- Left-aligned Heading -->
    <div class="heading d-flex gap-2 align-items-center text-white fw-600">
      <!-- Chat Bot -->
      <div class="chat-bot">
        <input type="text" placeholder="Ask RAMsys .." class="border-0 search-text-input form-control" [ngModel]="query"
          (ngModelChange)="query = $event" (input)="onInputChange($event)" />
        <button type="button" class="searchbtn">
          <img src="assets/images/searchbtn.svg" title="" alt="" />
        </button>
        <ul class="bot-dropdown-list vertical" *ngIf="showDropdown">
          <li *ngFor="let item of filteredItems">{{ item }}</li>
        </ul>
      </div>
    </div>

    <!-- Right-aligned Icons for Chat and notifications -->
    <div class="icons-wrapper" (clickOutside)="isShowChatUser=false" appClickOutside>
      <nb-icon nbPrefix icon="message-square-outline" pack="eva" class="chat-icon" (click)="showChatUsers()">
      </nb-icon>
      <div class="chat-users row" *ngIf="isShowChatUser">
        <ul *ngFor="let role of objectKeys(otherUsers)">
          <li>
            <div class="chat-user-role">{{ role }}</div>
            <div class="hoverable-action chat-users-drop-down" *ngFor='let user of otherUsers[role]'
              [matTooltip]="user.name" (click)="startUserChat(user)">
              {{ getTextToShow(user.name) }}
            </div>
          </li>
        </ul>
      </div>

      <i class="fa fa-bell" matBadge="{{ chatService.notificationsCountLimit }}" matBadgeColor="warn" aria-hidden="true"
        matBadgeSize="medium" matBadgeOverlap="true" matBadgePosition="after"
        [matBadgeHidden]="chatService.notificationsCount === 0" [matMenuTriggerFor]="menu" aria-label="menu"
        (click)="checkNotifications()">
      </i>
      <mat-menu #menu="matMenu" xPosition="before" class="notifications-menu">
        <button mat-menu-item class="noMessages" *ngIf="chatService.notifications.length === 0">
          {{ "HEADER.NO_MESSAGES" | translate }}
        </button>
        <button mat-menu-item class="notifications-menu-item" *ngFor='let notification of chatService.notifications'
          (click)="selectNotification(notification)">
          <span class="countBadge">{{ notification.count }}</span>
          {{ "HEADER.MESSAGES" | translate }}
          <ng-container *ngIf="notification.roomPurposeId; else elseBlock">
            {{ "HEADER.RELATED_TO" | translate }}
          </ng-container>
          <ng-template #elseBlock>
            {{ "HEADER.FROM" | translate }}
          </ng-template>
          <span class="newNotification" [matTooltip]="notification.title">
            {{ notification.title }}
          </span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>