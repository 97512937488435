import { Injectable } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { Subject } from 'rxjs';

interface AlarmUIState {
	isShowingMap?: boolean;
	selectedAssetId?: string;
	comments: string;
	lastWorkFlowQuestionState?: any;
	wasCommentBoxOpen?: boolean;
	wasDirectEscalationOpen?: boolean;
	wasSendMailBoxOpen?: boolean;
	wasChatBoxOpen?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class UserSessionStateService {

	alarmsState: Map<string, AlarmUIState> = new Map();
	confidenceMonitoringMode: boolean = false;
	thermalState: boolean = false;
	analyticalDashboardState: boolean = false;
	confidenceMonitoringChangeNotification = new Subject<boolean>();
	ThermalStateChangeNotification = new Subject<boolean>();
	analyticalDashboardChangeNotification = new Subject<boolean>();

	constructor() { }

	public getAlarmInitialState(): AlarmUIState {
		const val: AlarmUIState = {
			isShowingMap: true,
			selectedAssetId: null,
			comments: '',
			wasCommentBoxOpen: false,
			wasDirectEscalationOpen: false,
			wasSendMailBoxOpen: false,
			wasChatBoxOpen: false
		};
		return val;
	}

	public createAlarmEntry(alarm: Alarm) {
		if (!this.alarmsState.has(alarm.id)) {
			this.alarmsState.set(alarm.id, this.getAlarmInitialState());
		}
	}

	public createAlarmEntries(alarms: Alarm[]) {
		if (alarms) {
			alarms.forEach((alarm) => this.createAlarmEntry(alarm));
		}
	}

	public saveSelectedAssetId(alarmId: string, assetId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		if (alarmState) {
			alarmState.selectedAssetId = assetId;
		}
	}

	public getSelectedAssetDocId(alarmId: string): string {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return alarmState ? alarmState.selectedAssetId : null;
	}

	public saveComments(alarmId: string, comments: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.comments = comments ? comments : '';
	}

	public getComments(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return alarmState.comments;
	}

	public saveCommentBoxOpenState(alarmId: string, isOpen: boolean) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.wasCommentBoxOpen = !!isOpen;
	}

	public getCommentBoxOpenState(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return !!alarmState.wasCommentBoxOpen;
	}

	public saveDirectEscalationOpenState(alarmId: string, isOpen: boolean) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.wasDirectEscalationOpen = !!isOpen;
	}

	public getDirectEscalationOpenState(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return !!alarmState.wasDirectEscalationOpen;
	}

	public saveSendMailBoxOpenState(alarmId: string, isOpen: boolean) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.wasSendMailBoxOpen = !!isOpen;
	}

	public getSendMailBoxOpenState(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return !!alarmState.wasSendMailBoxOpen;
	}

	public saveChatBoxOpenState(alarmId: string, isOpen: boolean) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.wasChatBoxOpen = !!isOpen;
	}

	public getChatBoxOpenState(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return !!alarmState.wasChatBoxOpen;
	}

	public saveWorkflowQuestionState(alarmId: string, questionKey: string, questionState: any) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.lastWorkFlowQuestionState = {
			key: questionKey,
			state: questionState
		};
	}

	public deleteWorkflowQuestionState(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		alarmState.lastWorkFlowQuestionState = undefined;
	}

	public getWorkflowQuestionState(alarmId: string) {
		const alarmState: AlarmUIState = this.alarmsState.get(alarmId);
		return alarmState.lastWorkFlowQuestionState;
	}

	public modifyConfidenceMonitoringState(value: boolean) {
		this.confidenceMonitoringMode = value;
		this.confidenceMonitoringChangeNotification.next(this.confidenceMonitoringMode);
	}

	public getConfidenceMonitoringState() {
		return this.confidenceMonitoringMode;
	}

	public modifyAnalyticalDashboardState(value: boolean) {
		this.analyticalDashboardState = value;
		this.analyticalDashboardChangeNotification.next(this.analyticalDashboardState);
	}

	public getAnalyticalDashboardState() {
		return this.analyticalDashboardState;
	}

	public modifyThermalState(value: boolean) {
		this.thermalState = value;
		this.ThermalStateChangeNotification.next(this.thermalState);
	}

	public getThermalState() {
		return this.thermalState;
	}
	/**
	 * send notification to all subscribed component when there is change in alarms data
	 */
	public getConfidenceMonitorigChangeNotification() {
		return this.confidenceMonitoringChangeNotification.asObservable();
	}

	public getThermalStateChangeNotification() {
		return this.ThermalStateChangeNotification.asObservable();
	}

}
