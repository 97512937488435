import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import * as d3 from 'd3';
import { ScaleTime, ScaleLinear, ScaleBand } from 'd3-scale';

interface DataItem {
  time: string;
  count: number;
  percentage?: number;
}

interface ChartData {
  today: DataItem[];
  previous_day?: DataItem[];
  chart: string;
}

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss'],
})
export class BarchartComponent implements AfterViewInit {
  @Input() data: ChartData = { chart: '', today: [], previous_day: [] };
  @Input() width: number = 450;
  @Input() height: number = 400;
  @Input() margin = { top: 20, right: 20, bottom: 30, left: 40 };
  chartId: string = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.data.chart) {
      this.chartId = this.data.chart;
      this.cdr.detectChanges();
      this.drawChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['data'] && !changes['data'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['margin'] && !changes['margin'].firstChange)
    ) {
      this.drawChart();
    }
  }

  private convertToTime(time: string) {
    if (!time) return;
    const [hours, minutes] = time?.split(':')?.map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0);

    return date;
  }

  private createSvg(): d3.Selection<SVGSVGElement, unknown, HTMLElement, any> {
    d3.select(`figure#bar-chart`).select('svg').remove();
    return d3
      .select(`figure#bar-chart`)
      ?.append('svg')
      ?.attr('width', this.width)
      ?.attr('height', this.height);
  }

  private createScales(
    width: number,
    height: number
  ): {
    x: ScaleTime<number, number>;
    y: ScaleLinear<number, number>;
  } {
    const x: ScaleTime<number, number> = d3.scaleTime().rangeRound([0, width]);

    const y: ScaleLinear<number, number> = d3.scaleLinear().range([height, 0]);

    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    const today = new Date();
    const endTime = new Date(today);
    endTime.setHours(23, 0, 0, 0);

    x.domain([startTime, endTime]);
    y.domain([0, d3.max(this.data.today, (d: DataItem) => d.count) as number]);

    return { x, y };
  }

  private appendAxes(
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    height: number,
    width: number
  ): void {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    const today = new Date();
    const endTime = new Date(today);
    endTime.setHours(23, 0, 0, 0);
    chartGroup
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(d3.timeHour.every(4)).tickValues(d3.timeHour.range(startTime, endTime, 4).concat(endTime))
      .tickFormat(d3.timeFormat('%I %p')))
      .selectAll('text')
      .attr('font-size', '0.8vw')
      .attr('fill', 'white');

    const yAxis = chartGroup.append('g').call(d3.axisLeft(y).ticks(4));
    yAxis.selectAll('text')?.attr('font-size', '0.8vw').style('fill', 'white');

    chartGroup.selectAll('.domain').style('stroke', 'none');
    yAxis
      .selectAll('.tick')
      ?.select('line')
      ?.clone()
      ?.attr('class', 'grid-line')
      ?.attr('x1', 0)
      ?.attr('x2', width)
      ?.attr('y1', 0)
      ?.attr('y2', 0)
      ?.attr('stroke', '#ffffff20')
      ?.attr('stroke-width', 0.5);
  }

  private appendBars(
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    height: number
  ): void {
    const barWidth =
      (x(this.convertToTime(this.data.today[1]?.time)) -
        x(this.convertToTime(this.data.today[0]?.time))) /
      2;
    const areaGradient = chartGroup
      .append('defs')
      .append('linearGradient')
      .attr('id', 'areaGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    areaGradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#3CCE80 ')
      .attr('stop-opacity', 0.8);

    areaGradient
      .append('stop')
      .attr('offset', '50%')
      .attr('stop-color', '#CDA73A ')
      .attr('stop-opacity', 1);
    areaGradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#794540 ')
      .attr('stop-opacity', 1);

    const areaGradient2 = chartGroup
      .append('defs')
      .append('linearGradient')
      .attr('id', 'areaGradient2')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');
    chartGroup
      .selectAll('.bar')
      .data(this.data.today)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x(this.convertToTime(d?.time)) - barWidth / 2)
      .attr('y', (d) => y(d.count))
      .attr('width', barWidth)
      .attr('height', (d) => height - y(d.count))
      .attr('fill', 'url(#areaGradient)');

    if (this.data.previous_day && this.data.previous_day.length > 0) {
      const previousBarWidth =
        (x(this.convertToTime(this.data.previous_day[1]?.time)) -
          x(this.convertToTime(this.data.previous_day[0]?.time))) /
        2;

      chartGroup
        .selectAll('.bar-previous')
        .data(this.data.previous_day)
        .enter()
        .append('rect')
        .attr('class', 'bar-previous')
        .attr('x', (d) => x(this.convertToTime(d?.time)) - previousBarWidth / 2)
        .attr('y', (d) => y(d.count))
        .attr('width', previousBarWidth)
        .attr('height', (d) => height - y(d.count))
        .attr('fill', '#286ed3');
    }
  }
  private addHoverEffect(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    height: number
  ): void {
    const { tooltip, tooltipArrow } = this.createTooltip();

    const hoverBar = chartGroup
      .append('rect')
      .attr('class', 'hover-bar')
      .attr('width', 40)
      .attr('height', height)
      .attr('fill', 'transparent')
      .style('visibility', 'hidden');

    svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => {
        hoverBar.style('visibility', 'visible');
        tooltip.style('visibility', 'visible');
        tooltipArrow.style('visibility', 'visible');
      })
      .on('mousemove', (event) => {
        const mouse = d3.pointer(event);

        const xTime = x.invert(mouse[0] - this.margin.left);
        const bisect = d3.bisector((d: DataItem) =>
          this.convertToTime(d.time)
        ).left;

        const index = bisect(this.data.today, xTime, 1);

        const a = this.data?.today?.[index - 1];
        const b = this.data?.today?.[index];

        const d =
          xTime.getTime() - this?.convertToTime(a?.time)?.getTime() >
          this.convertToTime(b?.time)?.getTime() - xTime?.getTime()
            ? b
            : a;

        const previousIndex = bisect(
          this.data.previous_day || [],
          this.convertToTime(d?.time),
          1
        );
        const previousA = this?.data?.previous_day?.[previousIndex - 1];
        const previousB = this?.data?.previous_day?.[previousIndex];
        const previousD =
          previousA && previousB
            ? xTime.getTime() - this.convertToTime(previousA?.time).getTime() >
              this.convertToTime(previousB?.time)?.getTime() - xTime?.getTime()
              ? previousB
              : previousA
            : previousA || previousB;
        if (hoverBar) {
          const barWidth = 40;
          const barHeight =
            height - y(Math.max(d.count, previousD?.count ?? 0)) + 10;
          const barX =
            x(this.convertToTime(d?.time)) -
            (hoverBar.node()?.getBoundingClientRect().width ?? 0) / 2;
          const barY = height - barHeight;

          hoverBar.attr('x', barX);
          hoverBar.attr('height', barHeight);
          hoverBar.attr('y', barY);
        }

        const formatTime = d3.timeFormat('%I %p');
        const timeString = formatTime(this.convertToTime(d?.time));
        tooltip.html(
          `<div style="padding:10px;display:flex; flex-direction:column;gap:10px;">
            <div style="font-weight:bold;color:#1b1b25">${timeString}</div>
            <div style="color:#1b1b25">${
              this.data.chart === 'alarm-time-of-the-day' ? 'Today: ' : ''
            }${d.count} Alarms ${
            (d.percentage ?? 0) !== 0
              ? `(<span style="display: inline-flex; align-items: center;color:#1b1b25">
                    <svg width="15" height="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="${
                        (d.percentage ?? 0) < 0
                          ? 'M12 22 L2 2 L22 2 Z'
                          : 'M12 2 L22 22 L2 22 Z'
                      }" fill="${(d.percentage ?? 0) > 0 ? 'green' : 'red'}"/>
                    </svg>
                  </span> ${
                    (d.percentage ?? 0) > 0
                      ? d.percentage
                      : (d.percentage ?? 0).toString().slice(1)
                  }%)`
              : ''
          }</div>
             ${
               previousD?.count !== undefined
                 ? `<div style="color:#1b1b25">Previous Day: ${previousD.count} Alarms</div>`
                 : ''
             }
            </div>`
        );
        tooltip
          .style(
            'top',
            `${
              window.scrollY +
              (hoverBar.node()?.getBoundingClientRect().top ?? 0) -
              (tooltip.node()?.getBoundingClientRect().height ?? 0) -
              20
            }px`
          )
          .style(
            'left',
            `${
              window.scrollX +
              (hoverBar.node()?.getBoundingClientRect().left ?? 0) -
              (tooltip.node()?.getBoundingClientRect().width ?? 0) / 2 +
              (hoverBar.node()?.getBoundingClientRect().width ?? 0) / 2
            }px`
          );

        tooltipArrow
          .style(
            'top',
            `${
              window.scrollY +
              (tooltip.node()?.getBoundingClientRect().bottom ?? 0) -
              10
            }px`
          )
          .style(
            'left',
            `${
              window.scrollX +
              (tooltip.node()?.getBoundingClientRect().left ?? 0) +
              (tooltip.node()?.getBoundingClientRect().width ?? 0) / 2 -
              (hoverBar.node()?.getBoundingClientRect().width ?? 0) / 2
            }px`
          );
      })
      .on('mouseout', () => {
        hoverBar.style('visibility', 'hidden');
        tooltip.style('visibility', 'hidden');
        tooltipArrow.style('visibility', 'hidden');
      });
  }
  private createTooltip(): {
    tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
    tooltipArrow: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  } {
    const tooltip = d3
      .select('body')
      ?.append('div')
      ?.attr('class', 'tooltip-timeoftheday')
      ?.style('position', 'absolute')
      ?.style('visibility', 'hidden')
      ?.style('z-index', '1000')
      ?.style('background-color', 'white')
      ?.style('padding', '5px')
      ?.style('border-radius', '15px');

    const tooltipArrow = d3
      .select('body')
      ?.append('div')
      ?.attr('class', 'tooltip-arrow')
      ?.style('position', 'absolute')
      ?.style('visibility', 'hidden')
      ?.style('z-index', '1001');

    tooltipArrow.html(`<svg width="36" height="33" viewBox="0 0 36 13" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0503 -0.000288547L0.941395 -0.000288963C8.2062 -0.000288327 14.673 4.60335 17.051 11.468C17.3616 12.3648 18.6301 12.3648 18.9408 11.4679C21.3187 4.60334 27.7855 -0.000288229 35.0503 -0.000288547Z" fill="white"/>
        </svg>`);

    return { tooltip, tooltipArrow };
  }
  private drawChart(): void {
    const svg = this.createSvg();
    const width = this.width;
    const height = this.height;
    const margin = this.margin;

    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const { x, y } = this.createScales(
      width - margin.left - margin.right,
      height - margin.top - margin.bottom
    );

    this.appendAxes(
      chartGroup,
      x,
      y,
      height - margin.top - margin.bottom,
      width - margin.left - margin.right
    );

    this.appendBars(chartGroup, x, y, height - margin.top - margin.bottom);
    this.addHoverEffect(
      svg,
      chartGroup,
      x,
      y,
      height - margin.top - margin.bottom
    );
  }
}
