<div class="control-bar" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <i class="fa fa-arrows-alt" aria-hidden="true" cdkDragHandle></i>
    <nb-icon nbPrefix icon="close-outline" pack="eva" class="close-vnc" (click)="closeDialog()">
    </nb-icon>
</div>

<div class="dialog-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="iframe-container">
        <iframe width="100%" height="100%" frameBorder="0" [src]="iframeUrl | safe" allowfullscreen></iframe>
    </div>
</div>