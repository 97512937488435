import { Component, OnInit, OnChanges, Input, SimpleChanges, HostListener, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-total-alarm-progress',
  templateUrl: './total-alarm-progress.component.html',
  styleUrls: ['./total-alarm-progress.component.scss'],
})
export class TotalAlarmProgressComponent implements AfterViewInit, OnChanges {
  private margin = { top: 20, right: 20, bottom: 20, left: 20 };
  @Input() percentage: number = 0;
  @Input() width: number = 250;
  @Input() height: number = 40;
  @Input() intervals: number = 30;
  private svg: any;

  constructor() { }

  ngAfterViewInit(): void {
    this.createSvg();
    this.drawProgressBar();
    this.drawSplitLines();
    this.adjustLeftDiv();
  }
  @HostListener('window:resize')
  onResize(): void {
    this.createSvg();
    this.drawProgressBar();
    this.drawSplitLines();
    this.adjustLeftDiv();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['percentage'] && !changes['percentage'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['intervals'] && !changes['intervals'].firstChange)
    ) {
      this.createSvg();
      this.drawProgressBar();
      this.drawSplitLines();
      this.adjustLeftDiv();
    }
  }

  get absolutePercentage() {
    return this?.percentage;
  }

  private createSvg(): void {
    d3.select(`figure#progress-total`)
      ?.select('svg#progress-total-chart')
      ?.remove();
    this.svg = d3
      ?.select('figure#progress-total')
      ?.append('svg')
      ?.attr('id', 'progress-total-chart')
      ?.attr('width', this.width + this.margin.left + this.margin.right)
      ?.attr('height', this.height)
      ?.append('g')
      ?.attr('transform', `translate(40,0)`)
      ?.style('vertical-align', 'unset');

    const defs = this.svg?.append('defs');
    const gradient = defs?.append('linearGradient').attr('id', 'gradient');

    gradient
      ?.append('stop')
      ?.attr('offset', '0%')
      ?.attr('stop-color', '#19dd7f');

    if (this.percentage <= 33.3) {
      gradient
        ?.append('stop')
        ?.attr('offset', `${this.percentage}%`)
        ?.attr('stop-color', '#2aa4fd');
    } else if (this.percentage <= 50) {
      gradient
        ?.append('stop')
        ?.attr('offset', '10%')
        ?.attr('stop-color', '#19dd7f');
      gradient
        ?.append('stop')
        ?.attr('offset', '60%')
        ?.attr('stop-color', '#2aa4fd');
    } else if (this.percentage <= 80) {
      gradient
        ?.append('stop')
        ?.attr('offset', '10%')
        ?.attr('stop-color', '#19dd7f');
      gradient
        ?.append('stop')
        ?.attr('offset', '60%')
        ?.attr('stop-color', '#2aa4fd');
      gradient
        ?.append('stop')
        ?.attr('offset', `90%`)
        ?.attr('stop-color', '#ffba34');
    } else if (this.percentage <= 100) {
      gradient
        ?.append('stop')
        ?.attr('offset', '10%')
        ?.attr('stop-color', '#19dd7f');
      gradient
        ?.append('stop')
        ?.attr('offset', '40%')
        ?.attr('stop-color', '#2aa4fd');
      gradient
        ?.append('stop')
        ?.attr('offset', '70%')
        ?.attr('stop-color', '#ffba34');
      gradient
        ?.append('stop')
        ?.attr('offset', `100%`)
        ?.attr('stop-color', '#fd3e14');
    }
  }

  private adjustLeftDiv(): void {
    const svgWidth = this.width;
    const svgHeight = this.height;

    document.documentElement.style.setProperty('--svg-width', `${svgWidth}px`);
    document.documentElement.style.setProperty(
      '--svg-height',
      `${svgHeight}px`
    );
  }

  private drawProgressBar(): void {
    const validPercentage = Math.max(0, this.percentage); // Ensure percentage is non-negative
    const pathData = `M 0,0 
                    L 0,${this.height} 
                    L ${this.width - 25},${this.height} 
                    Q ${this.width},${this.height} ${this.width},${this.height - 25} 
                    L ${this.width},${25} 
                    Q ${this.width},0 ${this.width - 25},0 
                    Z`;

    this.svg
      .append('clipPath')
      .attr('id', 'clip-right-rounded')
      .append('path')
      .attr('d', pathData);

    this.svg
      .append('rect')
      .attr('class', 'progress-bar-background')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', '#e6e6e6')
      .attr('clip-path', 'url(#clip-right-rounded)');

    this.svg
      .append('rect')
      .attr('class', 'progress-bar-fill')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', (validPercentage / 100) * this.width)
      .attr('height', this.height)
      .attr('fill', 'url(#gradient)')
      .attr('clip-path', 'url(#clip-right-rounded)');
  }

  private drawSplitLines(): void {
    const intervalWidth = this?.width / this?.intervals;

    for (let i = 0; i < this.intervals; i++) {
      const xPosition = intervalWidth * i + 1;
      var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

      const dAttribute = `M${xPosition + 10} 0 
                          C${xPosition + 8.34315} 0 ${xPosition + 7} 1.34315 ${xPosition + 7
        } 3 
                          V${this.height - 3} 
                          C${xPosition + 7} ${this.height} ${xPosition + 8.34315
        } ${this.height} ${xPosition + 10} ${this.height} 
                          H${xPosition + 0.0637489} 
                          C${xPosition + 1.69117} ${this.height} ${xPosition + 3
        } ${this.height - 1.34315} ${xPosition + 3} ${this.height - 3} 
                          V3 
                          C${xPosition + 3} 1.34315 ${xPosition + 1.65685
        } 0 ${xPosition} 0 
                          Z`;

      path.setAttribute('d', dAttribute);
      path.setAttribute('fill', '#1a1a23');
      path.setAttribute('stroke', '#1a1a23');
      path.setAttribute('stroke-width', '0.2px');

      this?.svg?.node()?.appendChild(path);

      if (i === 0) {
        const pathRect = path?.getBoundingClientRect();

        const svgRect = this?.svg?.node()?.getBoundingClientRect();

        const pathLeftPositionRelativeToSVG = pathRect?.left - svgRect?.left;

        document.documentElement.style.setProperty(
          '--left-position',
          `${pathLeftPositionRelativeToSVG - 2}px`
        );
      }
    }
  }

  private updateProgressBar(): void {
    const progressWidth = (this.percentage / 100) * this.width;

    this.svg
      .select('.progress-bar-fill')
      .transition()
      .duration(500)
      .attr('width', progressWidth);
  }
}
