<div class="send-alarm-container">
  <div class="send-alarm-header">
    Add alarm
  </div>

  <div class="sites-stats">
    <app-sites-table [displayedColumns]="displayedColumns"></app-sites-table>
  </div>

  <div class="add-alarm">
    <app-add-alarm></app-add-alarm>
  </div>

</div>