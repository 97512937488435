<div class="main-body">
	<div class="alerts">
		<app-alarms></app-alarms>
	</div>
	<div class="assets">
		<div class="box-header">
			<div class="d-flex align-items-center">
				<i class="fa fa-bars" aria-hidden="true"></i>
			</div>
			<p class="assets-header">{{"DASHBOARD.HEADER_TEXT" | translate}}</p>
		</div>

		<div class="assets-section">
			<app-video-feeds class="video-feed-box" [shouldCloseFullScreen]="assetDocSelectedChanged" *ngIf="!isThermal">
			</app-video-feeds>
			<app-thermal-feeds class="video-feed-box" [alarmId]="selectedAlarmId" *ngIf="isThermal">
			</app-thermal-feeds>
		</div>

		<div class="asset-view-section">
			<app-asset-viewer [assetDoc]="assetDoc"></app-asset-viewer>
		</div>

	</div>

	<div class="right-content" [hidden]="confidenceMonitoringMode">
		<!-- <div class="alarm-details">
			<app-alarm-details [alarm]="selectedAlarm"></app-alarm-details>
		</div>
		<div class="workflow" [class.both-expanded]='isActionHistoryExpanded&&isAlarmHistoryExpanded'
			[class.both-collapsed]='!isActionHistoryExpanded&&!isAlarmHistoryExpanded'
			[class.one-collapsed]='(isActionHistoryExpanded&&!isAlarmHistoryExpanded)||(!isActionHistoryExpanded&&isAlarmHistoryExpanded)'>
			<app-workflow [alarm]="selectedAlarm"></app-workflow>
		</div>
		<div class="action-history-container">
			<div class="action-history">
				<app-action-history [alarm]="selectedAlarm"></app-action-history>
			</div>
			<div class="alarm-history">
				<app-alarm-history></app-alarm-history>
			</div>
		</div> -->

		<app-right-section></app-right-section>
	</div>
	<div *ngIf="isOperator && confidenceMonitoringMode" class="right-content">
		<app-confidence-monitor></app-confidence-monitor>
	</div>