import { Component, OnInit, Input } from '@angular/core';

interface AlarmInsightsData {
  label: string;
  key: string;
  value: string;
}

@Component({
  selector: 'app-alarm-insights',
  templateUrl: './alarm-insights.component.html',
  styleUrls: ['./alarm-insights.component.scss'],
})
export class AlarmInsightsComponent implements OnInit {
  @Input() data: AlarmInsightsData[] = [{ label: '', key: '', value: '' }];

  constructor() {}

  ngOnInit(): void {}
}
