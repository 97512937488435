import { Component, HostListener, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { InsightsService } from '../services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from '../utils/app-utils';

interface severityData {
  label: string;
  value: number;
}

interface incidentsData {
  label: string;
  key: string;
  value: number;
}

interface rangeData {
  label: string;
  value: string;
}

declare var $: any;

@Component({
  selector: 'app-right-section',
  templateUrl: './right-section.component.html',
  styleUrls: ['./right-section.component.scss'],
})

export class RightSectionComponent implements AfterViewInit, AfterViewChecked {
  startDate: string = '';
  endDate: string = '';
  isScreenSmall: boolean = false;
  isScreenHeightSmall: boolean = false;
  selectedTab: string = 'overview';
  title: string = 'General';
  subTitle: string = 'Overview';
  liveUpdates: Subscription;
  severityData: severityData[] = [];

  siteInsightData = [
    { label: 'All Sites', value: 0 },
    { label: 'Active Sites', value: 0 },
    { label: 'Inactive Sites', value: 0 },
  ];

  rangeData: rangeData[] = [
    {
      label: 'Day',
      value: 'day',
    },
    {
      label: 'Week',
      value: 'week',
    },
    {
      label: 'Month',
      value: 'month',
    },
  ]

  incidentsData: incidentsData[] = [
    {
      label: 'Handling',
      key: 'handling',
      value: 0
    },
    {
      label: 'Automation',
      key: 'automation',
      value: 0
    },
    {
      label: 'Escalated',
      key: 'escalated',
      value: 0
    },
    {
      label: 'False',
      key: 'false',
      value: 0
    },
  ];

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) {
    this.checkScreenWidth();
  }

  async ngOnInit() {
    await this.getInsights();
    await this.getLiveAlarmsData();
    await this.listAlarmsBySeverity();
    this.incidentsListener();
  }

  async getInsights() {
    let res = await this.insightsService.getSitesInsights();
    if (res && res.success && res.data && res.data.results) {
      this.updateSiteInsightData(res.data.results);
    } else {
      this.resetSiteInsightData();
    }
  }

  async getLiveAlarmsData() {
    let res = await this.insightsService.getLiveAlarms();
    if (res && res.success && res.data && res.data.results) {
      this.incidentsData.forEach(incident => {
        incident.value = res.data.results[incident.key] ?? 0;
      });
    } else {
      this.incidentsData.forEach(incident => {
        incident.value = 0;
      });
    }
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'alarmsUpdate') {
        await this.getLiveAlarmsData();
      } else if (update === 'newIncident') {
        await this.listAlarmsBySeverity();
      }
    });
  }

  async listAlarmsBySeverity() {
    const res = await this.insightsService.getAlarmsBySeverity();
    if (res && res.success && res.data && res.data.results) {
      this.severityData = res.data.results.map(item => {
        return {
          label: item.severity,
          value: item.currentPeriodAlarmsCount,
        };
      });
    } else {
      this.severityData = [];
    }
  }

  private updateSiteInsightData(data: { connected: number; disconnected: number; total: number }) {
    this.siteInsightData = [
      { label: 'All Sites', value: data.total },
      { label: 'Active Sites', value: data.connected },
      { label: 'Inactive Sites', value: data.disconnected },
    ];
  }

  private resetSiteInsightData() {
    // Set all values to zero if data fetching fails or data format is incorrect
    this.siteInsightData = [
      { label: 'All Sites', value: 0 },
      { label: 'Active Sites', value: 0 },
      { label: 'Inactive Sites', value: 0 },
    ];
  }

  @HostListener('window:resize', ['$event'])

  onResize() {
    this.checkScreenWidth();
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
    this.initializeCarousels();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.checkScreenWidth();
    this.initializeCarousels();
    this.cdr.detectChanges();
  }

  initializeCarousels(): void {
    if (this.selectedTab === 'overview' && $('#top-sites-alarm').length) {
      $('#top-sites-alarm').carousel({ wrap: true });
    }

    if (this.selectedTab === 'alarm' && $('#false-rate-alarms').length) {
      $('#false-rate-alarms').carousel({ wrap: true });
    }

    if (this.selectedTab === 'operator' && $('#operator-activity-tab').length) {
      $('#operator-activity-tab').carousel({ wrap: true });
    }
  }

  checkScreenWidth() {
    this.isScreenSmall = window.innerWidth < 1800;
    this.isScreenHeightSmall =
      window.innerHeight < 900 || window.innerWidth < 1300;
  }

  onTabSelected(tab: string) {
    this.selectedTab = tab;
    this.title =
      tab === 'overview'
        ? 'General'
        : tab === 'alarm'
          ? 'Alarm'
          : tab === 'operator'
            ? 'Operator'
            : tab === 'detailed'
              ? 'Detailed'
              : '';
    this.subTitle =
      tab === 'overview'
        ? 'System'
        : tab === 'alarm'
          ? 'Alarm'
          : tab === 'operator'
            ? 'Operator'
            : tab === 'detailed'
              ? 'Detailed'
              : '';
  }

  handleRangeClick(range: string) {
    console.log('range clicked:', range);
  }

  handleDateRangeSelected(dateRange: { startDate: string, endDate: string }) {
    console.log('Selected date range:', dateRange);
    this.startDate = dateRange.startDate;
    this.endDate = dateRange.endDate;
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }

}
