<div class="alarm-details-box">
	<div class="box-header">
		<!-- <div class="d-flex align-items-center">
			<i class="fa fa-bell" aria-hidden="true"></i>
		</div> -->
		<p class="details-header">{{ "ALARM_DETAILS.ALARM_DETAILS" | translate }}</p>
	</div>
	<div class="box-content">
		<table *ngIf="alarm; else noAlarm" aria-describedby="alarm-details" class="details-table">
			<th scope="row" aria-hidden="true"></th>
			<tr class="content-row row-12">
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<i class="fa" [ngClass]="getIconForStatus(alarm?.status)"></i>
					</div>
					<div>
						{{alarm?.status}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<i class="fa fa-calendar" aria-hidden="true"></i>
					</div>
					<div>
						{{formattedDate}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<i class="fa fa-hourglass-end" aria-hidden="true"></i>
					</div>
					<div>
						{{ "ALARM_DETAILS.ACTIVE_SINCE" | translate }} {{activeSince}}
					</div>
				</td>
			</tr>
			<tr class="content-row row-12">
				<td class="alarm-details-cell col-4">
					<div class="icon alarm-icon" [class.big-icon]="alarm.type === alarmType.TEMPERATURE">
						<img class="details-icon" alt="alarm-icon" [src]="alarm.icon">
					</div>
					<div>
						{{alarm?.shortAlarmId || alarm?.id}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<i class="fa fa-clock-o" aria-hidden="true"></i>
					</div>
					<div>
						{{getFormatedDate(alarm?.activationTime,appConstants.TIME_FORMAT)}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<i class="fa fa-map" aria-hidden="true"></i>
					</div>
					<div [matTooltip]='alarm?.branch?.address || alarm?.address' class="address">
						{{alarm?.branch?.address || alarm?.address}}
					</div>
				</td>
			</tr>
			<tr class="content-row row-12">
				<td class="alarm-details-cell col-12">
					<div class="icon">
						<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
					</div>
					<div class="alarmInfo" [matTooltip]='alarmInfo' [matTooltipClass]="'info-tooltip'">
						{{alarmInfo}}
					</div>
				</td>
			</tr>
		</table>
		<ng-template #noAlarm>
			<div class="box-content-no-data">
				{{ "ALARM_DETAILS.NO_DATA" | translate }}
			</div>
		</ng-template>
	</div>
</div>