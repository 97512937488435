import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-top-sites',
  templateUrl: './top-sites.component.html',
  styleUrls: ['./top-sites.component.scss'],
})

export class TopSitesComponent implements AfterViewInit, AfterViewChecked {
  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }
  dynamicWidth: number = 200;
  dynamicHeight: number = 200;
  dynamicRadius: number = 90;
  arcSize: number = 25;
  pieData = [];
  liveUpdates: Subscription;

  async ngOnInit() {
    await this.getTopSites();
    this.incidentsListener();
  }

  async getTopSites() {
    let res = await this.insightsService.getTopSitesRaisingAlarms();
    if (res && res.success === true && res.data.results && res.data.results.length > 0) {
      this.pieData = res.data.results.map(site => {
        return {
          label: site.branchName,
          value: site.currentPeriodAlarmsCount,
          percentage: site.rate === null ? 100 : site.rate
        };
      });
      this.cdr.detectChanges();
    } else {
      this.pieData = [];
    }
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'newIncident') {
        await this.getTopSites();
      }
    });
  }

  ngAfterViewInit() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  private updateChartWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth > 3000) {
      this.dynamicWidth = 400;
      this.dynamicHeight = 350;
      this.dynamicRadius = 130;
      this.arcSize = 35;
    } else {
      this.dynamicWidth = 200;
      this.dynamicHeight = 200;
      this.dynamicRadius = 90;
      this.arcSize = 25;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }

}
