import { FormGroup, FormControl } from '@angular/forms';
import { AppConstants } from '../constants/app-constants';

export class Utility {
	/**
	 * Validates all form fields.
	 * @param formGroup Form group instance.
	 */
	static validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				Utility.validateAllFormFields(control);
			}
		});
	}
	/**
	 * This utility avoids null pointer error by checking the sub field in the object.
	 *
	 * @param json Json object
	 * @param path Path to get the value.
	 */
	public static getPath(json: any, path: string): any {
		if (json && path && path.length) {
			const pathSplit: string[] = path.split('.');
			return Utility.getPath(json[pathSplit[0]], pathSplit.slice(1, pathSplit.length).join('.'));
		}
		return json;
	}

	/**
	 * Sleep for time (in milli seconds)
	 *
	 * @param time Time in milliseconds
	 */
	public static async sleep(time: number) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(time);
			}, time);
		});
	}

	/**
	 * called to format tiles in rows
	 */
	public static formatIntoRows(items: any) {
		const noOfItemsInRow = items.length < 6 ? 2 : 3;
		const rows = [];
		while (items.length !== 0) {
			rows.push(items.splice(0, noOfItemsInRow));
		}
		return rows;
	}

	/**
	 * called to get screen resolution
	 */
	public static getScreenSize() {
		const screenResolution = window.document.body.getClientRects();
		// console.log(screenResolution);
		if (screenResolution[0].width === 3440 && screenResolution[0].height === 1297) {
			return AppConstants.HALF_WFHD;
		} else if (screenResolution[0].width < 2561 && screenResolution[0].width > 1920 && screenResolution[0].height < 970) {
			return AppConstants.HALF_WUHD;
		} else if (screenResolution[0].width === 2560 && screenResolution[0].height <= 1080
		) {
			return AppConstants.WFHD;
		} else if (screenResolution[0].width <= 5120 && screenResolution[0].height <= 2160 && screenResolution[0].width > 3440 && screenResolution[0].height > 1440) {
			return AppConstants.WUHD;
		} else if (screenResolution[0].width <= 1920 && screenResolution[0].height <= 1200 && screenResolution[0].height >= 1080) {
			return AppConstants.WUXGA;
		} else if (screenResolution[0].width <= 1920 && screenResolution[0].height <= 1080) {
			return AppConstants.FULL_HD;
		} else {
			return AppConstants.NORMAL;
		}
	}

	/**
	 * called to show or hide the load mask
	 */
	public static toggleLoadMask(componentRef: any) {
		if (componentRef) {
			const main = document.querySelector('main');
			main.style.opacity = '1';
			main.removeChild(componentRef);
		} else {
			const main = document.querySelector('main');
			main.style.opacity = '0.2';
			const image = document.createElement('img');
			image.src = AppConstants.LOADMASK_SRC;
			image.className = 'loadmask-icon';
			main.appendChild(image);
			return image;
		}
	}

	/**
	 * called to data is array and has length more then 0
	 */
	public static isNotEmpty(data: any) {
		return !!(data && data.length > 0);
	}

	public static Unsubscribe(subscribedVariable: any) {
		if (subscribedVariable) {
			subscribedVariable.unsubscribe();
			return subscribedVariable;
		}
	}

	public static stringToArrayByComma(string: string) {
		if (Utility.isNotEmpty(string)) {
			let array = string.split(',').map((item: any) => {
				if (item !== '') {
					return item.trim();
				}
			});
			return array.filter((arrayEle: any) => arrayEle);
		}
	}

	/**
	 * get relative path of image
	 */
	public static getImagePath(imageName: string) {
		return '../../assets/' + imageName;
	}

}
