<div class="chart-block chartone-block radius-15 box-border d-flex flex-column">
  <div class="block-head d-flex align-items-center">
    <h2 class="title text-white">False Alarms Rate</h2>
    <app-popover [title]="'False alarms rate'"
      [content]="'Breakup of false alarms marked by RIC or Users'"></app-popover>
  </div>
  <div class="chart-wrap d-flex justify-content-center align-items-center">
    <div class="chart">
      <app-top-sites-donut [chartId]="'false-rate-alarms'" [data]="pieData" [width]="dynamicWidth"
        [height]="dynamicHeight" [radius]="dynamicRadius" [arcSize]="arcSize" [colors]="[
          ['#FE902B', '#FE902B'],
          ['#2AD36E', '#2AD36E']
        ]"></app-top-sites-donut>
    </div>
  </div>
</div>