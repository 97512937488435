<div class="container-fluid">
  <div id="first-block" [ngStyle]="isScreenHeightSmall ? { height: '62%' }:''" class="card-block dark">
    <div class="card-wrap d-flex justify-content-between">
      <app-navbar (tabSelected)="onTabSelected($event)"></app-navbar>
      <div class="card-content">
        <div class="gi-row d-flex justify-content-between mb-1" *ngIf="selectedTab !== 'live'">
          <div class="gi-text">
            <h1 class="title fw-300 text-white mb-1">
              {{ title }} <b>Insights</b>
            </h1>
            <div class="datepicker-range-container">
              <app-date-picker (dateRangeSelected)="handleDateRangeSelected($event)"></app-date-picker>
              <app-range-picker [ranges]="rangeData" (rangeClick)="handleRangeClick($event)"></app-range-picker>
            </div>
          </div>
          <div class="d-flex">
            <app-sites-insights [data]="siteInsightData"></app-sites-insights>
          </div>
        </div>
        <div *ngIf="selectedTab === 'overview'">
          <div *ngIf="!isScreenSmall" class="row mt-1 first-widget-container">
            <div class="col-lg-12 col-xxl-6 p-2">
              <app-top-sites></app-top-sites>
            </div>
            <div class="col-lg-12 col-xxl-6 p-2">
              <app-total-alarms></app-total-alarms>
              <app-alarm-operator-ratio></app-alarm-operator-ratio>
            </div>
          </div>
          <div *ngIf="isScreenSmall" id="top-sites-alarm" class="carousel slide" data-ride="carousel"
            data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="col-xxl-6 py-2 px-4">
                  <app-top-sites id="top-sites-alarm1"></app-top-sites>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-xxl-6 py-2 px-4">
                  <app-total-alarms id="top-sites-alarm2"></app-total-alarms>

                  <app-alarm-operator-ratio></app-alarm-operator-ratio>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#top-sites-alarm" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#top-sites-alarm" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedTab === 'alarm'">
          <div *ngIf="!isScreenSmall" class="row mt-1 first-widget-container">
            <div class="col-xxl-6 p-2">
              <app-false-rate></app-false-rate>
            </div>
            <div class="col-xxl-6 p-2">
              <app-repeated-alarms></app-repeated-alarms>
            </div>
          </div>
          <div *ngIf="isScreenSmall" id="false-rate-alarms" class="carousel slide" data-ride="carousel"
            data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="col-xxl-6 py-2 px-4">
                  <app-false-rate id="false-rate-alarms1"></app-false-rate>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-xxl-6 py-2 px-4">
                  <app-repeated-alarms id="false-rate-alarms2"></app-repeated-alarms>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#false-rate-alarms" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#false-rate-alarms" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedTab === 'operator'">
          <div id="operator-activity-tab" class="carousel slide" data-ride="carousel" data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="col-xxl-12 py-2 px-4">
                  <app-operator-activity id="operator2"></app-operator-activity>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-xxl-12 py-2 px-4">
                  <app-operator-ranking id="operator2"></app-operator-ranking>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#operator-activity-tab" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#operator-activity-tab" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedTab === 'detailed'" class="card-wrap d-flex justify-content-between">
          <div class="row mt-1">
            <div class="col-lg-12">
              <app-alarm-severity [data]="severityData"></app-alarm-severity>
            </div>
            <div class="col-lg-12 mt-2">
              <app-alarm-time-of-the-day></app-alarm-time-of-the-day>
            </div>
          </div>
        </div>
        <div *ngIf="isScreenHeightSmall && selectedTab === 'live'" id="second-block" class="card-block dark mt-1">
          <div class="card-wrap">
            <app-incidents-till-now [data]="incidentsData"></app-incidents-till-now>
            <app-realtime-alarms></app-realtime-alarms>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bot-assist dark my-2">
    <app-chat-bar></app-chat-bar>
  </div>

  <div *ngIf="!isScreenHeightSmall" id="second-block" class="card-block dark mt-1">
    <div class="card-wrap">
      <app-incidents-till-now [data]="incidentsData"></app-incidents-till-now>
      <app-realtime-alarms></app-realtime-alarms>
    </div>
  </div>
</div>