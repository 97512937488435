import { Subject } from 'rxjs';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';
import { Utility } from '../utils/app-utils';
import { Subscription } from 'rxjs';
import { Alarm } from "src/models/alarm.model";
import { MapService } from '../services/map.service';

enum AssetDocType {
	image = 'img',
	pdf = 'pdf'
}

@Component({
	selector: 'app-asset-viewer',
	templateUrl: './asset-viewer.component.html',
	styleUrls: ['./asset-viewer.component.scss'],
	// encapsulation: ViewEncapsulation.None
})

export class AssetViewerComponent implements OnInit {
	public shouldShowImg = false;
	public shouldShowPDF = false;
	public assetUrl: string = null;
	utility = Utility;

	public isContainZone: boolean = false;
	selectedAlarmSubscriber: Subscription;
	sitesChangedSubscriber: Subscription;
	selectedSite: any;

	@Input() assetDoc: Subject<any>;
	selectedAlarm: Alarm;
	selectedTab: string = 'progress';
	public assetDocSelectedChanged = false;

	constructor(
		public alarmService: AlarmService, public mapService: MapService,
		private userSessionStateService: UserSessionStateService,
		public confidenceMonitoringService: ConfidenceMonitoringService) {
		this.assetDoc = null;
	}

	ngOnInit() {
		this.assetDoc.subscribe((asset) => {
			this.initDefaults();
			// If asset is null or undefined it mean's there is no asset doc selected
			if (asset) {
				this.shouldShowImg = asset.type === AssetDocType.image;
				this.shouldShowPDF = asset.type === AssetDocType.pdf;
				this.assetUrl = asset.url;
			}
		});

		this.confidenceMonitoringService.getAssetSelectedNotification().subscribe((asset) => {
			this.initDefaults();
			// If asset is null or undefined it mean's there is no asset doc selected
			if (asset) {
				this.shouldShowImg = asset.type === AssetDocType.image;
				this.shouldShowPDF = asset.type === AssetDocType.pdf;
				this.assetUrl = asset.url;
			}
		});

		this.selectedAlarmListener();
		this.selectedSiteListener();
	}

	public initDefaults() {
		// By default show map view
		this.shouldShowImg = false;
		this.shouldShowPDF = false;
		this.assetUrl = null;
	}

	close() {
		this.onAssetSelectShow(null);
		this.shouldShowPDF = false;
		this.shouldShowImg = false;
		if (this.selectedAlarm) {
			this.userSessionStateService.saveSelectedAssetId(this.selectedAlarm.id, null);
		}
		// Send/Change state after closing the asset while using Monitoring Mode 
		// this.mapService.modifyAssetState(this.selectedAlarm.id,); 
		const confidenceMonitoringMode = this.userSessionStateService.getConfidenceMonitoringState();
		if (confidenceMonitoringMode) {
			this.mapService.modifyAssetState(this.selectedSite);
		}
	}

	ngOnDestroy(): void {
		this.selectedAlarmSubscriber.unsubscribe();
		this.sitesChangedSubscriber.unsubscribe();
	}

	//  called when alarm is selected
	selectedAlarmListener() {
		this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			if (alarm) {
				this.selectedAlarm = alarm;
				this.isContainZone = this.alarmService.isContainZone(alarm);
				this.close();

				if (!this.isContainZone && this.selectedTab === 'zone') {
					this.selectedTab = 'progress';
				}

				// Ensure a valid tab is selected when switching alarms
				this.onTabSelected(this.selectedTab);
			}
		});
	}

	// called when site is selected in Confidence Monitoring mode
	selectedSiteListener() {
		this.sitesChangedSubscriber = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
			this.initDefaults();
			this.selectedSite = branch;
			this.mapService.modifyAssetState(branch);
		});
	}

	onTabSelected(tab: string) {
		this.close();
		if (tab === 'zone' && !this.isContainZone) {
			this.selectedTab = 'progress';
		} else {
			this.selectedTab = tab;
		}
	}

	onAssetSelectShow(asset: any) {
		this.assetDocSelectedChanged = null;
		this.assetDoc.next(asset);
		// Let Angular CD to detect change.
		setTimeout(() => {
			this.assetDocSelectedChanged = asset ? true : false;
		}, 100);
	}

}
