import { Injectable } from '@angular/core';
import { ApiResponse } from "src/models/api-response.model";
import { ApiConstants } from "../constants/api-constants";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  date = new Date();
  endDate = this.date.getTime();
  startDate = 1725262561158;

  updateLiveIincidents = new Subject<any>();

  constructor(private http: HttpClient) { }

  async getTopSitesRaisingAlarms() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_TOP_SITES, { params }));
    return response;
  }

  async getTotalAlarms() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_TOTAL_ALARMS, { params }));
    return response;
  }

  async getSitesInsights() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SITES_INSIGHTS, { params }));
    return response;
  }

  async getOperatorsActionRanking() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATORS_ACTIONS_RANKING, { params }));
    return response;
  }

  async getAlarm_operator_ratio() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARM_OPERATOE_RATIO, { params }));
    return response;
  }

  async getLiveAlarms() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_LIVE_ALARMS, { params }));
    return response;
  }

  async getLiveIncidents(slotMinutes: number) {
    let params = new HttpParams();
    params = params.set('__slotMinutes', slotMinutes);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_LIVE_INCIDENTS, { params }));
    return response;
  }

  updateIncidents() {
    return this.updateLiveIincidents.asObservable();
  }

  async getOperatorActivity() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATOR_ACTIVITY, { params }));
    return response;
  }

  async getRepeatedAlarmTypes() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_REPEATED_ALARM_TYPES, { params }));
    return response;
  }

  async getFalseAlarmsRate() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_FALSE_ALARMS_RATE, { params }));
    return response;
  }

  async getAlarmsBySeverity() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_BY_SEVERITY, { params }));
    return response;
  }

  async getAlarmsByTimeOfTheDay() {
    let params = new HttpParams();

    if (this.startDate) {
      // params = params.set('options[__startDate]', this.startDate.toString());
    }

    if (this.endDate) {
      // params = params.set('options[__endDate]', this.endDate.toString());
    }

    // params = params.set('options[status[]]', Open);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_BY_TIME_OF_THE_DAY, { params }));
    return response;
  }

}


