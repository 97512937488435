<div class="box-header d-flex align-items-center">
	<div class="d-flex align-items-center">
		<i class="fa fa-bars" aria-hidden="true"></i>
	</div>
	<div class="ml-2">
		{{ "CONFIDENCE_MONITORING.SITES" | translate }}
	</div>
</div>

<div class="sites-stats-div">
	<div class="filter-content">
		<app-alarms-filter [filterType]="filterType" (changeHeight)="changeTableSize()"
			[isConfidenceMonitoring]="isConfidenceMonitoring">
		</app-alarms-filter>
	</div>

	<div>
		<app-sites-table [displayedColumns]="displayedColumns"></app-sites-table>
	</div>
</div>