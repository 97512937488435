import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { ConfidenceMonitoringService } from 'src/app/services/confidence-monitoring.service';
import { Utility } from 'src/app/utils/app-utils';
import { UserSessionStateService } from 'src/app/services/user-session-state.service';

@Component({
	selector: 'app-sites-table',
	templateUrl: './sites-table.component.html',
	styleUrls: ['./sites-table.component.scss']
})
export class SitesTableComponent implements OnInit {

	public branchDetailDataSource: any = [];
	public selectedBranchId = null;
	public selectedBranch;
	getConfidenceMonitoringSubscriber: any;
	public selectedSitesAsset = this.analyticalDashboard.sitesAsset.MAP;
	@Input() displayedColumns = [];
	@Output() selectedBranchEvent = new EventEmitter<any>();
	branchChangeSubscriber: any;

	constructor(
		public analyticalDashboard: AnalyticalDashboardService,
		public confidenceMonitoringService: ConfidenceMonitoringService,
		public userSessionStateService: UserSessionStateService
	) { }

	ngOnInit() {
		this.getBranchStatus();
		this.branchChangeSubscriber = this.analyticalDashboard.getBranchNotification().subscribe(() => {
			this.selectBranch();
		});

		this.analyticalDashboard.getSitesSelectedAssetDocs().subscribe((selectedSitesAsset) => {
			this.selectedSitesAsset = selectedSitesAsset;
		});

		this.getConfidenceMonitoringSubscriber = this.userSessionStateService.getConfidenceMonitorigChangeNotification().subscribe((state) => {
			if (state) {
				this.selectBranch();
			}
		});
	}

	ngOnDestroy() {
		Utility.Unsubscribe(this.branchChangeSubscriber);
		Utility.Unsubscribe(this.getConfidenceMonitoringSubscriber);
	}

	selectBranch() {
		this.branchDetailDataSource = this.analyticalDashboard.branchDataSource ? this.analyticalDashboard.branchDataSource : [];
		if (this.branchDetailDataSource.length) {
			this.selectedBranchId = this.branchDetailDataSource[0].id;
			this.selectedBranch = this.branchDetailDataSource[0];
			this.analyticalDashboard.selectAlarm(this.selectedBranch);
			this.confidenceMonitoringService.changeSelectedSite(this.selectedBranch);
			this.selectedBranchEvent.emit(this.selectedBranch);
		}
	}

	async getBranchStatus() {
		const list = await this.analyticalDashboard.getBranchStatusReport();
		this.branchDetailDataSource = list;
		if (this.branchDetailDataSource.length) {
			this.selectedBranchId = this.branchDetailDataSource[0].id;
			this.selectedBranch = this.branchDetailDataSource[0];
			this.analyticalDashboard.selectAlarm(this.selectedBranch);
			this.confidenceMonitoringService.changeSelectedSite(this.selectedBranch);
			this.selectedBranchEvent.emit(this.selectedBranch);
		}
	}


	/**
	 * format assets name
	 */
	formatAssetsNames(assets: any) {
		return assets ? assets.map(asset => asset.name).join(', ') : '';
	}

	/**
	 * get assets count
	 */
	formatAssetsCounter(assets: any) {
		return assets ? assets.length : 0;
	}

	/**
	 * format operators name
	 */
	formatOperatorsNames(operators: any) {
		return operators ? operators.map(operator => `${operator.firstName} ${operator.lastName}`).join(', ') : '';
	}

	/**
	 * get operators count
	 */
	formatOperatorsCounter(operator: any) {
		return operator ? operator.length : 0;
	}

	/**
	 * select and highlight a row
	 */
	highlight(row: any) {
		this.selectedBranchId = row.id;
		this.selectedBranch = row;
		this.analyticalDashboard.selectAlarm(this.selectedBranch);
		this.confidenceMonitoringService.changeSelectedSite(this.selectedBranch);
		this.selectedBranchEvent.emit(this.selectedBranch);
	}

}
