import { Component, OnInit, Input } from '@angular/core';
import { formatValue } from 'src/app/utils/commonfunctions';
interface insightsData {
  label: string;
  value: number;
}
@Component({
  selector: 'app-sites-insights',
  templateUrl: './sites-insights.component.html',
  styleUrls: ['./sites-insights.component.scss'],
})
export class SitesInsightsComponent implements OnInit {
  @Input() data: insightsData[] = [];

  constructor() { }

  ngOnInit(): void { }

}
