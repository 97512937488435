<div class="chart-block chartthree-block radius-15 box-border d-flex flex-column">
  <div class="block-head d-flex align-items-center">
    <h2 class="title text-white">Alarm to Operator ratio</h2>
    <app-popover [title]="'Alarm to operator ratio'"
      [content]="'Total alarms vs. operator involved and their individual impacts'"></app-popover>
  </div>
  <div id="operator-alarm"
    class="chart-wrap d-flex justify-content-between align-items-center alarm-operator-ratio-alarm-container">
    <div class="chart">
      <app-alarm-operator-progress [percentage]="alarmsPercentage" [width]="dynamicWidth" [height]="dynamicHeight"
        [intervals]="dynamicIntervals" [chartId]="'alarm-operator-ratio-alarm'"
        [colors]="['#f67630', '#f26427', '#ef5920']">
      </app-alarm-operator-progress>
    </div>
    <div class="chart-value d-flex border-0">
      <div class="item-value align-items-center d-flex">
        <div class="d-flex flex-column">
          <span>Alarms</span>
          <p class="paragraph-text">{{ totalAlarms }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-wrap d-flex justify-content-between align-items-center">
    <div class="chart">
      <app-alarm-operator-progress [percentage]="operatorsPercentage" [width]="dynamicWidth" [height]="dynamicHeight"
        [intervals]="dynamicIntervals" [chartId]="'alarm-operator-ratio-operator'"
        [colors]="['#32cc87', '#35a6e5', '#1b7aaf']"></app-alarm-operator-progress>
    </div>
    <div class="chart-value d-flex border-0">
      <div class="item-value align-items-center d-flex">
        <div class="d-flex flex-column">
          <span>Operator</span>
          <p class="paragraph-text">{{ totalOperators }}</p>
        </div>
      </div>
    </div>
  </div>
</div>