import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { HierarchyNode } from 'd3';

interface treemapObject {
  label: string;
  key: string;
  value: number;
  percentage: number;
}

interface treemapData {
  children: treemapObject[];
}
@Component({
  selector: 'app-treemap',
  templateUrl: './treemap.component.html',
  styleUrls: ['./treemap.component.scss'],
})
export class TreemapComponent implements OnInit {
  @Input() data: treemapData = {
    children: [],
  };
  @Input() width: number = 320;
  @Input() height: number = 300;
  @Input() colors = ['#08306b', '#08519c', '#2171b5', '#4292c6', '#6baed6'];

  constructor() { }

  ngOnInit(): void {
    this.createTreemap();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['data'] && !changes['data'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['colors'] && !changes['colors'].firstChange)
    ) {
      d3.select('#treemap svg')?.remove();
      this.createTreemap();
    }
  }

  private createTreemap(): void {
    const treemapLayout = d3
      .treemap<treemapData>()
      .size([this.width, this.height])
      .padding(2);

    const root = d3
      .hierarchy<{
        children: {
          label: string;
          key: string;
          value: number;
          percentage: number;
        }[];
      }>(this.data)
      .sum((d: any) => d.percentage);
    treemapLayout(root as unknown as HierarchyNode<treemapData>);

    const svg = d3
      .select('#treemap')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    const nodes = svg
      .selectAll('g')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('transform', (d: any) => `translate(${d.x0},${d.y0})`);

    nodes
      .append('rect')
      .attr('id', (d: any, index: number) => `treemap-rect-${index}`)
      .attr('width', (d: any) => d.x1 - d.x0 - 2)
      .attr('height', (d: any) => d.y1 - d.y0 - 3)
      .attr('fill', (d: any, index: number) => {
        return this.colors[index];
      })
      .attr('rx', 10)
      .attr('ry', 10);

    nodes
      .append('foreignObject')
      .attr('x', 5)
      .attr('y', 5)
      .attr('width', (d: any) => Math.max(0, d.x1 - d.x0 - 20))
      .attr('height', (d: any) => Math.max(0, d.y1 - d.y0 - 20))
      .append('xhtml:div')
      .style('color', 'white')
      .style('font-size', '16px')
      .style('overflow', 'hidden')
      .style('word-wrap', 'break-word')
      .style('white-space', 'pre-wrap')
      .style('display', 'flex')
      .style('height', '100%')
      .style('width', '100%')
      .html((d: any) => {
        return `
        <span style="flex-grow: 1;">${d.data.label}</span>
        <img src="${(d.data.icon)}" style="width: 30px; height: 30px;" />
      `;
      });

    nodes
      .append('text')
      .attr('x', 5)
      .attr(
        'y',
        (d: any, index: number) =>
          +d3.select(`#treemap-rect-${index}`)?.attr('height') / 2 + 10
      )
      .attr('fill', 'white')
      .attr('font-size', '21px')
      .attr('stroke', 'white')
      .attr('stroke-width', '1px')
      .text((d: any) => `${d.data.value}`);

    nodes
      .append('text')
      .attr('x', 5)
      .attr(
        'y',
        (d: any, index: number) =>
          +d3.select(`#treemap-rect-${index}`)?.attr('height') - 5
      )
      .attr('font-size', '16px')
      .attr('fill', 'white')
      .text((d: any) => `${d.data.percentage}%`);
  }
}
