import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss']
})
export class RangePickerComponent implements OnInit {

  constructor() { }
  @Input() ranges: [] = [];
  @Output() rangeClick = new EventEmitter<any>();
  selectedRange: string = "day";

  ngOnInit(): void {
  }

  onRangeClick(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const range = selectElement.value;
    this.rangeClick.emit(range);
    this.selectedRange = range;
  }

}
