import { Component, OnInit } from '@angular/core';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { ConfidenceMonitoringService } from 'src/app/services/confidence-monitoring.service';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-send-alarm',
  templateUrl: './send-alarm.component.html',
  styleUrls: ['./send-alarm.component.scss']
})
export class SendAlarmComponent implements OnInit {
  displayedColumns = [
    'Sites',
    'Region',
    'Site Priority',
    'Status'
  ];
  filterType = 'SITES';
  isConfidenceMonitoring = true;
  SITES_TABLE_ID = 'sites-table-container';
  utility = Utility;

  constructor(public analyticalDashboard: AnalyticalDashboardService,
    public confidenceMonitoringService: ConfidenceMonitoringService) { }

  ngOnInit() {
  }

  changeTableSize() {
    this.confidenceMonitoringService.changeTableSizeOnAddedFilter(this.SITES_TABLE_ID);
  }

}
