<div class="assets-viewer-container">

	<div class="tabs-wrap d-flex">
		<app-alarm-tabs (tabSelected)="onTabSelected($event)"></app-alarm-tabs>
		<!-- Alarm progress Tab -->
		<div *ngIf="selectedTab === 'progress'" class="tab-content">
			<app-alarm-progress [alarm]="selectedAlarm"></app-alarm-progress>
		</div>

		<!-- Workflow Tab -->
		<div *ngIf="selectedTab === 'workflow'" class="tab-content">
			<app-workflow [alarm]="selectedAlarm"></app-workflow>
		</div>

		<!-- Action history Tab -->
		<div *ngIf="selectedTab === 'actions'" class="tab-content">
			<app-action-history [alarm]="selectedAlarm"></app-action-history>
		</div>

		<!-- Map Tab -->
		<div *ngIf="selectedTab === 'map'" class="tab-content">
			<app-map shouldShowSearchInput="true"></app-map>
		</div>

		<!-- Zone Tab -->
		<div *ngIf="selectedTab === 'zone' && isContainZone" class="tab-content">
			<app-zone></app-zone>
		</div>

		<!-- Assets Tab -->
		<div *ngIf="selectedTab === 'assets'" class="tab-content">
			<app-asset-docs [alarm]="selectedAlarm" (selectedAssetDoc)="onAssetSelectShow($event)"
				*ngIf="!assetDocSelectedChanged"></app-asset-docs>
			<div class="assets-viewer">
				<app-pdf-viewer [hidden]="!shouldShowPDF" [url]="assetUrl" class="pdf-viewer-cmp"
					(closePDF)="close()"></app-pdf-viewer>
				<app-img-viewer [hidden]="!shouldShowImg" [url]="assetUrl" class="pdf-viewer-cmp"
					(closeImg)="close()"></app-img-viewer>
			</div>
		</div>

		<!-- Send-alarm Tab -->
		<div *ngIf="selectedTab === 'send-alarm'" class="tab-content">
			<app-send-alarm></app-send-alarm>
		</div>

	</div>

</div>