import { Component, OnInit, Input, SimpleChanges, AfterViewInit, OnChanges, HostListener } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-alarm-operator-progress',
  templateUrl: './alarm-operator-progress.component.html',
  styleUrls: ['./alarm-operator-progress.component.scss'],
})
export class AlarmOperatorProgressComponent
  implements OnInit, AfterViewInit, OnChanges {
  private margin = { top: 20, right: 10, bottom: 20, left: 10 };
  @Input() progress: number = 0;
  @Input() chartId: string = '';
  @Input() colors: string[] = ['#32cc87', '#35a6e5', '#1b7aaf'];
  @Input() width: number = 300;
  @Input() height: number = 30;
  @Input() intervals: number = 30;
  @Input() percentage: number = 50;
  private svg: any;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.createSvg();
    this.drawProgressBar();
    this.drawSplitLines();
    this.drawBackground();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.createSvg();
    this.drawProgressBar();
    this.drawSplitLines();
    this.drawBackground();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['chartId'] && !changes['chartId'].firstChange) ||
      (changes['progress'] && !changes['progress'].firstChange) ||
      (changes['colors'] && !changes['colors'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['percentage'] && !changes['percentage'].firstChange) ||
      (changes['intervals'] && !changes['intervals'].firstChange)
    ) {
      this.createSvg();
      this.drawProgressBar();
      this.drawSplitLines();
      this.drawBackground();
      this.updateGradient();
    }
  }

  private createSvg(): void {
    d3.select(`figure#${this.chartId}`)?.select('svg')?.remove();
    this.svg = d3
      .select(`figure#${this.chartId}`)
      ?.append('svg')
      ?.attr('width', this.width + this.margin.left + this.margin.right)
      ?.attr('height', this.height)
      ?.append('g')
      ?.attr('transform', `translate(0,0)`);

    const defs = this.svg.append('defs');
    const gradient = defs
      .append('linearGradient')
      .attr('id', `gradient-${this.chartId}`);

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', this.colors[0]);

    gradient
      .append('stop')
      .attr('offset', '66.6%')
      .attr('stop-color', this.colors[1]);

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.colors[2]);
  }
  private updateGradient(): void {
    const gradient = this?.svg?.select(`#gradient-${this.chartId}`);

    gradient.selectAll('stop').remove();

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', this.colors[0]);

    gradient
      .append('stop')
      .attr('offset', '66.6%')
      .attr('stop-color', this.colors[1]);

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.colors[2]);
  }

  private drawProgressBar(): void {
    this.svg
      .append('rect')
      .attr('class', 'progress-bar-background')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', '#373647')
      .attr('rx', 25)
      .attr('ry', 25);
  }

  private drawSplitLines(): void {
    const intervalWidth = this.width / this.intervals;
    for (let i = 0; i < this.intervals; i++) {
      const xPosition = intervalWidth * i + 1;
      var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

      const dAttribute = `M${xPosition + 10} 0 
                          C${xPosition + 8.34315} 0 ${xPosition + 7} 1.34315 ${xPosition + 7
        } 3 
                          V${this.height - 3} 
                          C${xPosition + 7} ${this.height} ${xPosition + 8.34315
        } ${this.height} ${xPosition + 10} ${this.height} 
                          H${xPosition + 0.0637489} 
                          C${xPosition + 1.69117} ${this.height} ${xPosition + 3
        } ${this.height - 1.34315} ${xPosition + 3} ${this.height - 3} 
                          V3 
                          C${xPosition + 3} 1.34315 ${xPosition + 1.65685
        } 0 ${xPosition} 0 
                          Z`;

      path.setAttribute('d', dAttribute);
      path.setAttribute('fill', '#1a1a23');
      path.setAttribute('fill-rule', 'evenodd');
      path.setAttribute('clip-rule', 'evenodd');
      path.setAttribute('stroke', '#1a1a23');
      path.setAttribute('stroke-width', '0.2px');

      this?.svg?.node()?.appendChild(path);
    }
  }

  private drawBackground(): void {
    if (this.percentage === 0) {
      // Don't draw anything if the percentage is zero
      return;
    }

    const minWidth = Math.max(5, (this.percentage / 100) * this.width); // Minimum width for small percentages
    this.svg
      .append('rect')
      .attr('class', 'progress-bar-fill')
      .style('position', 'relative')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', minWidth) // Apply the minWidth
      .attr('height', this.height)
      .attr('fill', `url(#gradient-${this.chartId})`)
      .attr('rx', 20)
      .attr('ry', 20);
  }

  getDivStyle() {
    const baseWidth = this.width;
    const progressWidth = (this.percentage / 100) * baseWidth;

    if (this.percentage === 0) {
      return {
        left: '0px',
      };
    }

    let leftPosition = progressWidth - 50 / 2 - 5;
    if (leftPosition < 0) {
      leftPosition = 0;
    }

    return {
      left: `${leftPosition}px`,
    };
  }

}
