import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { AppConstants } from 'src/app/constants/app-constants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InsightsService } from 'src/app/services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-alarm-operator-ratio',
  templateUrl: './alarm-operator-ratio.component.html',
  styleUrls: ['./alarm-operator-ratio.component.scss'],
})
export class AlarmOperatorRatioComponent
  implements AfterViewInit, AfterViewChecked {

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService, public authenticationService: AuthenticationService) { }

  dynamicWidth: number = 300;
  dynamicHeight: number = 30;
  dynamicIntervals: number = 30;
  alarmsPercentage: number = 0;
  operatorsPercentage: number = 0;
  totalAlarms: number = 0;
  totalOperators: number = 0;

  liveUpdates: Subscription;

  async ngOnInit() {
    await this.getRatio();
    this.incidentsListener();
  }

  async getRatio() {
    let res = await this.insightsService.getAlarm_operator_ratio();
    if (res && res.success && res.data && res.data.results) {
      this.totalAlarms = res.data.results.alarmsAcknowledgedByOperators.currentPeriodAlarmsCount;
      this.alarmsPercentage = Math.round(res.data.results.alarmsAcknowledgedByOperators.percentage);
      const isSupervisor = this.authenticationService.hasRole(AppConstants.Supervisor);
      if (isSupervisor) {
        this.operatorsPercentage = Math.round(res.data.results.allOpeartorsActivity.percentage);
        this.totalOperators = res.data.results.allOpeartorsActivity.totalOperators;
      } else {
        this.operatorsPercentage = Math.round(res.data.results?.individualOperatorActivity.percentage);
        this.totalOperators = Object.keys(res.data.results?.alarmsAcknowledgedByOperators.activeOperators).length;
      }
    } else {
      this.alarmsPercentage = 0;
      this.operatorsPercentage = 0;
      this.totalAlarms = 0;
      this.totalOperators = 0;
    }
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      await this.getRatio();
    });
  }

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }
  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    const parentWidth = document
      .querySelector('#operator-alarm')
      ?.getBoundingClientRect().width;

    const screenWidth = window.innerWidth;
    if (screenWidth > 3000) {
      this.dynamicWidth = parentWidth ? parentWidth - 100 : 500;
      this.dynamicHeight = 40;
      this.dynamicIntervals = 60;
    } else {
      this.dynamicWidth = parentWidth ? parentWidth - 100 : 300;
      this.dynamicHeight = 30;
      this.dynamicIntervals = 30;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }
}
