import { Component, OnInit } from '@angular/core';
import { UserSessionStateService } from '../services/user-session-state.service';
import { ChatService } from '../services/chat.service';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { AuthenticationService } from '../services/authentication.service';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../utils/app-utils';

@Component({
  selector: 'app-chat-bar',
  templateUrl: './chat-bar.component.html',
  styleUrls: ['./chat-bar.component.scss']
})
export class ChatBarComponent implements OnInit {
  query: string = '';
  filteredItems: string[] = [];
  showDropdown: boolean = false;

  items: string[] = [
    'Alarm by time of the day',
    'Repeated Alarms',
    'Alarm by severity',
  ];

  isShowNotificatiins: boolean = false;
  isShowChatUser: boolean = false;
  notificationsCounterSubscriber: Subscription;
  roomId: string;
  systemUsers: Array<any> = [];
  otherUsers: Array<any> = [];
  userMap = new Map();

  constructor(public chatService: ChatService, private appConfigService: AppConfigService, private authenticationService: AuthenticationService, public alarmService: AlarmService,) { }

  async ngOnInit() {
    await this.getUsers();
    await this.getNotifications();
    await this.getAllNotifications();
    await this.notificationsListner();

    // ReOpen opendChatRooms on page reload/refresh
    let opendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
    opendChatRooms.forEach((room: { roomId: string, title: string }) => {
      let data = { _id: room.roomId, title: room.title };
      this.chatService.openChatWindow(data);
    });
    await this.authenticationService.getSystemInfo();
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.query = inputElement.value;
    this.filteredItems = this.items.filter((item) =>
      item.toLowerCase().includes(this.query.toLowerCase())
    );
    if (this.filteredItems.length > 0) {
      this.filteredItems = this.filteredItems;
    } else {
      this.filteredItems.push('No Options Available');
    }
    this.showDropdown = this.query.length > 0;
  }

  showChatUsers() {
    this.isShowNotificatiins = false;
    this.isShowChatUser = !this.isShowChatUser;
  }

  async openChat_fromNotifcation(notification: any) {
    await this.chatService.openChatWindow(notification);
  }

  async getUsers() {
    this.systemUsers = await this.authenticationService.getSystemUsers();
    this.systemUsers.forEach((user) => {
      this.userMap.set(user._id, { role: user.role[0], name: `${user.firstName} ${user.lastName}` });
    });

    let CurrentUser = this.authenticationService.getSession();
    let chatUsers = this.systemUsers.filter((user) => user._id !== CurrentUser.userId);

    // Sort chatUsers by rolePosition
    chatUsers.sort((a, b) => a.rolePosition - b.rolePosition);

    let otherUsers = chatUsers.map((user) => ({
      name: `${user.firstName} ${user.lastName}`,
      role: user.role[0],
      id: user._id
    }));

    this.otherUsers = this.groupUsersByRole(otherUsers);
  }

  groupUsersByRole(users: any[]) {
    const grouped = users.reduce((acc, user) => {
      if (!acc[user.role]) {
        acc[user.role] = [];
      }
      acc[user.role].push(user);
      return acc;
    }, {});

    // Sort each group alphabetically in a case-insensitive manner
    for (const role in grouped) {
      grouped[role].sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    };

    return grouped;
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  async startUserChat(user: any) {
    user.title = user.role + ': ' + user.name;
    this.chatService.getDirectChat(user.id).subscribe(async (room: any) => {
      if (room) {
        room.title = user.title;
        await this.chatService.openChatWindow(room);
      } else {
        let room = await this.chatService.createPrivateRoom(user);
        await this.chatService.openChatWindow(room);
      }
    }
    );
  }

  async getAllNotifications() {
    let res = await this.chatService.getAllGroupedNotifications();
    if (res && res.success === true && res.data && res.data.notifications.length > 0) {
      let allNotifications = res.data.notifications.map((notification: any) => {
        const representativeUserId = notification.users[0];
        const user = this.userMap.get(representativeUserId);
        return {
          ...notification,
          title: notification.roomPurposeId ? notification.roomTitle : `${user.role}: ${user.name}`,
          count: notification.users.length
        };
      });
      allNotifications.forEach(notification => {
        this.chatService.groupedNotificationsCount[notification._id] = notification.count;
      });
    }
  }

  async getNotifications() {
    let res = await this.chatService.getGroupedNotifications();
    if (res && res.success === true && res.data && res.data.notifications.length > 0) {
      this.chatService.notifications = res.data.notifications.map((notification: any) => {
        const representativeUserId = notification.users[0];
        const user = this.userMap.get(representativeUserId);
        return {
          ...notification,
          title: notification.roomPurposeId ? notification.roomTitle : `${user.role}: ${user.name}`,
          count: notification.users.length
        };
      });
      this.chatService.notificationsCount = res.data.count;
    } else {
      this.chatService.notificationsCount = 0;
    };

    this.chatService.updateNotificationsDisplayCount();
  }

  async notificationsListner() {
    this.notificationsCounterSubscriber = this.alarmService.notificationListner().subscribe(async (data) => {
      const newNotification = data.notification;
      const representativeUserId = newNotification.details.fromUserId;
      const roomId = newNotification.details.roomId;

      // If this chat room is already open, mark the notification as seen.
      let opendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
      const roomExists = opendChatRooms.some((room: any) => room.roomId === roomId);
      if (roomExists) {
        const fakeNotification = { _id: roomId };
        await this.chatService.set_RoomNotifications_seen(fakeNotification._id);
        return;
      }

      // To store groupedNotificationsCount for each notification
      if (this.chatService.groupedNotificationsCount[roomId]) {
        this.chatService.groupedNotificationsCount[roomId]++;
      } else {
        this.chatService.groupedNotificationsCount[roomId] = 1;
      }

      const user = this.userMap.get(representativeUserId);

      const existingNotification = this.chatService.notifications.find(n => n._id === roomId);
      if (existingNotification) {
        // Assumes that the existing notification has a `users` array
        existingNotification.users.push(representativeUserId);
        // existingNotification.count = existingNotification.users.length;
        existingNotification.count = this.chatService.groupedNotificationsCount[roomId];
        if (existingNotification.originalIds) {
          existingNotification.originalIds.push(newNotification._id);
        } else {
          existingNotification.originalIds = [newNotification._id];
        }
        // Remove the existing notification from its current position
        this.chatService.notifications = this.chatService.notifications.filter(n => n._id !== roomId);
        // Unshift the updated notification to the beginning/top of the array
        this.chatService.notifications.unshift(existingNotification);
      } else {
        const groupedNotification = {
          _id: roomId,
          users: [representativeUserId],
          roomPurposeId: newNotification.details.roomPurposeId ? newNotification.details.roomPurposeId : null,
          title: newNotification.details.roomPurposeId ? newNotification.details.roomTitle : `${user.role}: ${user.name}`,
          count: this.chatService.groupedNotificationsCount[roomId],
          originalIds: [newNotification._id]
        };
        this.chatService.notifications.unshift(groupedNotification);
        this.chatService.notificationsCount += 1;
        let audio = new Audio('../assets/audio/chat.mp3');
        audio.play();
      }
      const limit = this.appConfigService.appConfig.NOTIFICATIONS_COUNT_LIMIT;
      while (this.chatService.notifications.length > limit) {
        this.chatService.notifications.pop(); // Remove the oldest notification
      }
      // Update the display count as well
      this.chatService.updateNotificationsDisplayCount();
    });
  }

  getTextToShow(text: string) {
    text = text.trim();
    return text.length > 31 ? text.substring(0, 30) + '...' : text;
  }

  async selectNotification(notification: any) {
    await this.openChat_fromNotifcation(notification);
    await this.getNotifications();
  }

  checkNotifications() {
    this.isShowChatUser = false;
    this.isShowNotificatiins = !this.isShowNotificatiins;
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.notificationsCounterSubscriber);
  }

}
