import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { InsightsService } from 'src/app/services/insights.service';
import { AppSessionStorage } from 'src/app/utils/app-session-storage-utils';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-operator-activity',
  templateUrl: './operator-activity.component.html',
  styleUrls: ['./operator-activity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OperatorActivityComponent implements AfterViewInit, AfterViewChecked {
  selectedChart: string = 'bar';
  dynamicWidth: number = 300;
  dynamicHeight: number = 240;

  data = {
    chart: 'operator-activity',
    today: []
  };

  operators: { id: string, name: string }[] = [];
  selectedOperatorId: string = '';
  liveUpdates: Subscription;

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }

  async ngOnInit() {
    await this.getActivity();
    this.incidentsListener();
  }

  async getActivity(operatorId?: string) {
    const res = await this.insightsService.getOperatorActivity();
    this.operators = res.data.results.map(result => ({
      id: result.user._id,
      name: `${result.user.firstName} ${result.user.lastName}`
    }));

    this.selectedOperatorId = operatorId ? operatorId : this.operators[0]?.id;

    const operatorActivities = res.data.results.find(op => op.user._id === this.selectedOperatorId)?.activities;

    // Update the chart data based on the selected operator
    this.data = {
      chart: res.data.name,
      today: this.calculateChartValues(operatorActivities)
    };
  }

  calculateChartValues(activities: any) {
    if (!activities) {
      return [];
    }

    const timestampCounts = activities;
    const user = AppSessionStorage.getUser();
    const timeZone = user.timeZone;

    const seriesData = Object.keys(timestampCounts).map(timestamp => {
      const date = new Date(parseInt(timestamp));
      const formatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone,
        hour12: false
      });

      const formattedTime = formatter.format(date);

      return {
        time: formattedTime,
        count: timestampCounts[timestamp],
      };
    });

    return this.fillMissingTimes(seriesData, timeZone);
  }

  fillMissingTimes(data: any, timeZone: string) {
    const allTimes = Array.from({ length: 24 }, (_, i) => {
      const utcDate = new Date(Date.UTC(1970, 0, 1, i, 0, 0));
      const formatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone,
        hour12: false
      });

      return formatter.format(utcDate);
    });

    const dataMap = data.reduce((acc: any, item: any) => {
      acc[item.time] = (acc[item.time] || 0) + item.count;
      return acc;
    }, {});

    return allTimes.map(time => ({
      time,
      count: dataMap[time] || 0,
    }));
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'alarmsUpdate') {
        await this.getActivity();
      }
    });
  }

  onOperatorChange(newOperatorId: string) {
    this.getActivity(newOperatorId);
  }

  onOperatorChange2(event: any) {
    const selectedOperator = event.target.value;
    this.getActivity(selectedOperator);
  }

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    const parentWidth = document
      .querySelector('#operator-activity')
      ?.getBoundingClientRect()?.width;
    if (parentWidth) {
      this.dynamicWidth = parentWidth ? parentWidth - 30 : 300;
      this.dynamicHeight = window.innerHeight > 1300 ? 350 : 240;
    }
  }

  onChartClick(type: string) {
    this.selectedChart = type;
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }

}
