import { Component, OnInit } from '@angular/core';
import { InsightsService } from 'src/app/services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-operator-ranking',
  templateUrl: './operator-ranking.component.html',
  styleUrls: ['./operator-ranking.component.scss'],
})

export class OperatorRankingComponent implements OnInit {
  tableData = {
    columns: [
      { label: 'Operator Name', key: 'name' },
      { label: 'Acknowledged', key: 'acknowledged' },
      { label: 'Verified', key: 'verified' },
      { label: 'Escalated', key: 'escalated' },
      { label: 'False', key: 'false' },
      { label: 'Resolved', key: 'resolved' },
    ],
    values: []
  };

  liveUpdates: Subscription;

  constructor(public insightsService: InsightsService) { }

  async ngOnInit() {
    await this.getOperatorsRanking();
    this.incidentsListener();
  }

  async getOperatorsRanking() {
    let res = await this.insightsService.getOperatorsActionRanking();
    if (res && res.success && res.data && res.data.results) {
      this.tableData.values = this.formatTableData(res.data.results);
    } else {
      this.tableData.values = [];
    }
  }

  private formatTableData(data: any[]): any[] {
    return data.map(item => ({
      name: `${item.user.firstName} ${item.user.lastName}`,
      acknowledged: item.actions.acknowledge ?? '-',
      verified: item.actions.verify ?? '-',
      escalated: item.actions.escalate ?? '-',
      false: item.actions.false ?? '-',
      resolved: item.actions.resolve ?? '-'
    }));
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'alarmsUpdate') {
        await this.getOperatorsRanking();
      }
    });
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }

}
