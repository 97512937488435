import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-false-rate',
  templateUrl: './false-rate.component.html',
  styleUrls: ['./false-rate.component.scss'],
})
export class FalseRateComponent implements AfterViewInit, AfterViewChecked {
  dynamicWidth: number = 230;
  dynamicHeight: number = 250;
  dynamicRadius: number = 100;
  arcSize: number = 25;

  pieData = [];

  liveUpdates: Subscription;

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }

  async ngOnInit() {
    await this.getRate();
    this.incidentsListener();
  }

  async getRate() {
    const res = await this.insightsService.getFalseAlarmsRate();
    if (res && res.success && res.data && res.data.results) {
      this.pieData = res.data.results.map(item => {
        return {
          label: item.role,
          value: item.currentPeriodAlarmsCount,
          percentage: Math.round(item.percentage)
        };
      });
    } else {
      this.pieData = [];
    }
  }

  incidentsListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'alarmsUpdate') {
        await this.getRate();
      }
    });
  }

  ngAfterViewInit() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }
  ngAfterViewChecked() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  private updateChartWidth() {
    const screenWidth = window.innerWidth;
    const screenheight = window.innerHeight;
    if (screenWidth > 3000 && screenheight > 1300) {
      this.dynamicWidth = 400;
      this.dynamicHeight = 350;
      this.dynamicRadius = 130;
      this.arcSize = 35;
    } else {
      this.dynamicWidth = 230;
      this.dynamicHeight = 250;
      this.dynamicRadius = 100;
      this.arcSize = 25;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }
}
