<div class="d-flex justify-content-around align-items-center donut-container"
  style="position: relative; height: 100%; width: 100%;">

  <figure [id]="chartId"></figure>
  <div id="labels" class="legends">
    <div *ngFor="let slice of data; let i = index" class="legend-container-each">
      <svg width="15" height="25">
        <rect x="10" y="10" width="20" height="30" [attr.fill]="colorsGradients[i]"></rect>
      </svg>
      <div class="legend-text-container">
        <span class="text-city" [matTooltip]="slice.label">{{ slice.label }}</span>
        <span class="text-alarms">{{ slice.value }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!(data && data.length > 0)" class="no-data-message">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>