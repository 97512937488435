<div class="chart-block charttwo-block radius-15 box-border d-flex flex-column mb-2">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Operator Ranking</h2>
      <app-popover [title]="'Operator ranking'"
        [content]="'Top operators based on number of alarms acknowledged'"></app-popover>
    </div>
  </div>
  <div class="chart-wrap">
    <app-operator-ranking-table [data]="tableData"></app-operator-ranking-table>
  </div>
</div>