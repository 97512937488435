<div class="chart-value d-flex border-0">
  <div *ngFor="let alarm of data">
    <div class="item-value text-center align-items-center d-flex">
      <img
        [src]="
          alarm.key === 'new'
            ? 'assets/images/newicon.svg'
            : alarm.key === 'active'
            ? 'assets/images/activeicon.svg'
            : alarm.key === 'closed'
            ? 'assets/images/resolvedicon.svg'
            : ''
        "
      />
      <div class="d-flex flex-column">
        <span>{{ alarm.label }}</span>
        <p class="paragraph-text">{{ alarm.value }}</p>
      </div>
    </div>
  </div>
</div>
