<div class="chart-block chartfour-block radius-15 box-border d-flex justify-content-between align-items-center gap-5x">
  <div class="block-head-alarm-rate d-flex align-items-center">
    <h2 class="title text-white">Alarms By Severity</h2>
    <app-popover [title]="'Alarms by severity'" [content]="'Number of alarms based on severity'"></app-popover>
  </div>
  <div class="chart-wrap d-flex justify-content-between align-items-center flex-column">
    <div class="chart-value d-flex border-0">
      <div *ngFor="let severity of data">
        <div class="item-value text-center d-flex align-items-center">
          <span>{{ severity.label }}</span>
          <p class="paragraph-text fw-700">
            {{ severity.value }}
          </p>
          <div class="down">
            <img *ngIf="severity.value !== 0" [src]="severity.value > 0 ? 'assets/images/uparrow.svg' 
                : 'assets/images/downarrow.svg'" alt="up/down" class="arrow-img" title="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>