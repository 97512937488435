<div class="chart-block charttwo-block time-of-day radius-15 box-border d-flex flex-column mb-2">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Alarm by time of the day</h2>
      <app-popover [title]="'Alarm by time of the day'"
        [content]="'Cummulative sum of alarms for each hour of the day'">
      </app-popover>
    </div>
    <div class="filter-block d-flex align-items-center">
      <label for="status-select" class="sr-only">Status</label>
      <select id="status-select" class="form-select" name="status">
        <option>Status</option>
        <option>Open</option>
        <option>Acknowldeged</option>
        <option>Escalated</option>
      </select>
      <label for="type-select" class="sr-only">Type</label>
      <select id="type-select" class="form-select" name="type">
        <option>Type</option>
        <option>Fire</option>
        <option>Panic</option>
      </select>
      <label for="severity-select" class="sr-only">Severity</label>
      <select id="severity-select" class="form-select" name="severity">
        <option>Severity</option>
        <option>Critical</option>
        <option>High</option>
      </select>
    </div>
  </div>
  <div class="chart-wrap" id="time-of-the-day">
    <div class="chart">
      <app-time-of-the-day-linechart
        [data]="data"
        [width]="dynamicWidth"
        [height]="dynamicHeight"
        [colors]="['#2B79ED', '#F4CEA9']"
        [legends]="['Current', 'Previous']">
      </app-time-of-the-day-linechart>
    </div>
  </div>
</div>
