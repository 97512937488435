import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utility } from '../utils/app-utils';

@Component({
  selector: 'app-vnc-dialog',
  templateUrl: './vnc-dialog.component.html',
  styleUrls: ['./vnc-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VncDialogComponent implements OnInit {

  iframeUrl: any;
  utility = Utility;

  constructor(public dialog: MatDialog, private dialogRef: MatDialogRef<VncDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.iframeUrl = this.data;
  }

  openDialog() {
    const dialogRef = this.dialog.open(VncDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
